import React from 'react'
// Material
import IconButton from '@mui/material/IconButton';
// Icons
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// Components
import LanguagesMenu from './MainListMenu/LanguagesMenu';
const MainList = () => {

    return (
        <div className='main-header-menu'>
            <IconButton aria-label="SearchOutlinedIcon">
                <SearchOutlinedIcon />
            </IconButton>
            
            <LanguagesMenu />

            <IconButton aria-label="NotificationsNoneOutlinedIcon">
                <NotificationsNoneOutlinedIcon />
            </IconButton>
        </div>
    )
}

export default MainList