import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import i18n from "i18next"
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
// Languages
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'ar'],
        fallbackLng: "en",
        detection: {
            order: ['cookie', 'htmlTag', 'localStorage'],
            caches: ['cookie']
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json'
        }
    })

// Theme
let theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#f7941e',
            contrastText: 'rgba(255,255,255,0.87)',
        },
        secondary: {
            main: '#F4F5FA',
            contrastText: '#524E5A',
        },
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}><Router><App /></Router></ThemeProvider>
);



