import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";

// Material
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const SecondaryList = () => {
    const {t} = useTranslation()

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    return (
        <List component={Stack} direction="row">
            <Link to="/">
                <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                >
                    <ListItemText primary={t("home")} />
                </ListItemButton>
            </Link>
            <Link to="/about">
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                >
                    <ListItemText primary={t("about")} />
                </ListItemButton>
            </Link>
            <Link to="/services">
                <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                >
                    <ListItemText primary={t("services")} />
                </ListItemButton>
            </Link>
            <Link to="/contact">
                <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                >
                    <ListItemText primary={t("contact_us")} />
                </ListItemButton>
            </Link>
        </List>
    )
}

export default SecondaryList