import { useState } from 'react';
import { lazy, Suspense } from 'react'
import { Routes, Route, Outlet } from "react-router-dom";
import { HeaderContext } from './helper/Context';

// Material
import LinearProgress from '@mui/material/LinearProgress';
// Compnents
import Header from "./Components/Header/Header";
// Pages
const Home = lazy(() => import('./Pages/Home/Home'))
const Enquiry = lazy(() => import('./Pages/Enquiry/Enquiry'))
const About = lazy(() => import('./Pages/About/About'))



const App = () => {

    // Authentication
    const [isLoged, setIsLoged] = useState(false)

    // page direction
    let pageDir = document.querySelector('html').dir



    return (
        <HeaderContext.Provider value={{ isLoged, pageDir }}>
            <Header />
            <Routes>
                <Route path='/' element={<LayOut />}>
                    <Route path="/" element={<Home />} exact></Route>
                    <Route path="/enquiry" element={<Enquiry />} exact></Route>
                    <Route path="/about" element={<About />} exact></Route>
                </Route>
            </Routes>
        </HeaderContext.Provider>
    )
}


const LayOut = () => {
    return (
        <Suspense fallback={<div style={{ width: '100%' }}> <LinearProgress /> </div>}>
            <Outlet />
        </Suspense>
    )
}

export default App