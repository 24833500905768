import { LazyLoadImage } from 'react-lazy-load-image-component';
import './style.css'
// Assets
import logo from '../../Assets/logo-black.webp'

// components
import MainList from './MainList';
import SecondaryList from './SecondaryList';
const Header = () => {
    return (
        <div className='header'>

            <div className='main-header'>
                <div className='header-width'>
                    <div className='main-header-logo'>
                        <LazyLoadImage
                            alt="Logo"
                            src={logo}
                        />
                    </div>
                    <MainList />
                </div>
            </div>

            <div className='secondary-header'>
                <div className='header-width'>
                    <SecondaryList />
                </div>
            </div>
        </div>
    )
}

export default Header